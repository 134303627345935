import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Container, Row, Col, Button } from "react-bootstrap"

import { LocaleContext } from "../layout"
import theme from "../theme"
import pattern from "../../images/content/pattern-001.png"
import pattarnSm from "../../images/content/pattern-sm-001.jpg"
import pattarnSm2x from "../../images/content/pattern-sm-001@2x.jpg"

const Section = styled.section`
  padding: 0;
  background-image: image-set( url(${pattarnSm}) 1x, url(${pattarnSm2x}) 2x);
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 220px;
  margin-bottom: -190px;

  ${theme.breakpoint.md} {
    display: block;
    background-image: none;
    transform: translateY(-50%);
    margin-bottom: -80px;
    min-height: 0;

    .container {
      padding: 1.5rem;
      box-shadow: ${theme.shadow.main};
      background: url("${pattern}") #fff;
      border-bottom: 1px solid ${theme.colors.primary};
    }

    p {
      width: 20%;
    }
  }
`
const Logo = styled.span`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: ${theme.shadow.main};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  transform: translateY(-50%);

  img {
    width: 50%;
    height: 50%;
  }

  ${theme.breakpoint.md} {
    margin: 0;
    margin-right: 1.5rem;
    transform: none;
  }
`
const Header = styled.div`
  display: none;
  align-items: flex-start;
  flex-direction: column;

  h2 {
    text-shadow: ${theme.shadow.main};
    margin-right: 2rem;
  }
  h3 {
    font-size: 1rem;
    font-style: italic;
    text-shadow: ${theme.shadow.main};
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: ${theme.colors.info};
      margin-top: 0.5rem;
    }
  }

  ${theme.breakpoint.md} {
    display: flex;
  }

  ${theme.breakpoint.lg} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
const BannerInfo = props => {
  const {
    pageContext: { lang, defaultPath }, ...context
  } = React.useContext(LocaleContext)

  const pathPrefix = context.path === defaultPath ? "" : `/${lang}`

  const tran = {
    en: {
      spendTime: "Spend some quality time with us",
      discover: "Discover more",
    },
    th: {
      spendTime: "พร้อมส่งมอบความประทับใจ",
      discover: "รับข้อมูลเพิ่มเติม",
    },
    cn: {
      spendTime: "與我們共度美好時光",
      discover: "Discover more",
    },
  }
  
  return (
    <Section>
      <Container>
        <Row>
          <Col
            md="8"
            lg="9"
            xl="8"
            className="d-flex justify-content-md-end justify-content-lg-start justify-content-xl-end align-items-center"
          >
            <Logo>
              <img
                src="/images/triple-l-hotel-logo_symbol.svg"
                alt="Triple L Hotel Logo"
              />
            </Logo>

            <Header>
              <h2>Triple L Hotel Phuket</h2>
              <h3>{tran[lang].spendTime}</h3>
            </Header>
          </Col>

          <Col
            md="4"
            lg="3"
            xl="4"
            className="d-flex justify-content-end justify-content-xl-start pl-xl-5 align-items-center"
          >
            <Button
              as={Link}
              to={`${pathPrefix}/about-hotel/`}
              variant="primary"
              className="py-3 px-5 d-none d-md-inline-block shadow"
            >
              {tran[lang].discover}
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default BannerInfo
