import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Container, Row, Col } from "react-bootstrap"

import { LocaleContext } from "../layout"
import theme from "../theme"
import overviewS01 from "../../images/content/triple-l-hotel-overview-s-01.jpg"
import overviewS012x from "../../images/content/triple-l-hotel-overview-s-01@2x.jpg"
import overviewS02 from "../../images/content/triple-l-hotel-overview-s-02.jpg"
import overviewS022x from "../../images/content/triple-l-hotel-overview-s-02@2x.jpg"
import overview01 from "../../images/content/triple-l-hotel-overview-01.jpg"
import overview012x from "../../images/content/triple-l-hotel-overview-01@2x.jpg"
import overview02 from "../../images/content/triple-l-hotel-overview-02.jpg"
import overview022x from "../../images/content/triple-l-hotel-overview-02@2x.jpg"
import overview03 from "../../images/content/triple-l-hotel-overview-03.jpg"
import overview032x from "../../images/content/triple-l-hotel-overview-03@2x.jpg"
import overview04 from "../../images/content/triple-l-hotel-overview-04.jpg"
import overview042x from "../../images/content/triple-l-hotel-overview-04@2x.jpg"
import overview05 from "../../images/content/triple-l-hotel-overview-05.jpg"
import overview052x from "../../images/content/triple-l-hotel-overview-05@2x.jpg"
import overview06 from "../../images/content/triple-l-hotel-overview-06.jpg"
import overview062x from "../../images/content/triple-l-hotel-overview-06@2x.jpg"
import overview07 from "../../images/content/triple-l-hotel-overview-07.jpg"
import overview072x from "../../images/content/triple-l-hotel-overview-07@2x.jpg"
import overview08 from "../../images/content/triple-l-hotel-overview-08.jpg"
import overview082x from "../../images/content/triple-l-hotel-overview-08@2x.jpg"

const Welcome = styled.section`
  padding-top: 3rem;
  padding-bottom: 2rem;

  ${theme.breakpoint.md} {
    padding: 5rem 0;
  }
`

const SubTitle = styled.h3`
  font-size: 1rem;
  text-align: center;
  font-family: ${theme.font.main};
  background: ${theme.colors.info};
  color: #fff;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  border-radius: 18px;
  margin: 0 auto 2rem;
  box-shadow: ${theme.shadow.main};

  ${theme.breakpoint.md} {
    text-transform: uppercase;
    text-align: left;
    font-family: ${theme.font.header};
    margin: 1rem 0;
    background: transparent;
    color: ${theme.colors.secondary};
    padding: 0;
    display: block;
    border-radius: 18px;
    box-shadow: none;

    &:after {
      content: "";
      width: 5rem;
      height: 3px;
      display: block;
      margin-top: 0.5rem;
      background: ${theme.colors.info};
    }
  }
`

const Title = styled.h2`
  margin-bottom: 1rem;
  font-size: 2rem;
`

const Image = styled.div`
  width: 100%;
  height: 260px;
  overflow: hidden;
  box-shadow: ${theme.shadow.main};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  ${theme.breakpoint.mdOnly} {
    height: 360px;
  }
  ${theme.breakpoint.lg} {
    height: 380px;
  }
`

const ImgSm = styled.div`
  width: 47%;
  height: 150px;
  overflow: hidden;
  box-shadow: ${theme.shadow.main};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${theme.breakpoint.mdOnly} {
    &:first-of-type {
      width: 60%;
    }
    &:last-child {
      width: 35%;
    }
  }

  ${theme.breakpoint.lg} {
    &:first-of-type {
      width: 100%;
      margin-top: 4.5rem;
    }
    &:last-child {
      width: 60%;
      margin-top: 2rem;
    }
  }
`

const WelcomeSection = () => {
  const {
    pageContext: { lang },
  } = React.useContext(LocaleContext)

  const t = {
    en: {
      subTitle: "Welcome To Triple L hotel",
      title: "Patong Beachfront Getaway",
      content: `Triple L Hotel is a first-class International Resort, located on a
      hilltop close to Patong Beach, one of the most famous destinations
      in Phuket, with access to all of the unique natural or cultural
      attractions and have fun with classic tropical beach activities.`,
      aboutHotel: `About Hotel`,
      bookRoom: `Reservation`
    },
    th: {
      subTitle: "ยินดีต้อนรับสู่ Triple L Hotel",
      title: "ประตูสู่หาดป่าตอง",
      content: `Triple L Phuket เป็นโรงแรมชั้นนำระดับนานาชาติ ตั้งอยู่บนเนินเขาด้านเหนือ
      ของหาดป่าตอง ซึ่งถือเป็นแหล่งท่องเที่ยวชั้นนำ ของภูเก็ต คุณสามารถเพลิดเพลินกับแหล่งท่องเที่ยว
      ตามธรรมชาติที่สวยงาม หรือแหล่งท่องเที่ยวเชิงวัฒนธรรมและประวัติศาสตร์ รวมถึงกิจกรรมสนุกๆอีกมากมาย`,
      aboutHotel: `เกี่ยวกับโรงแรม`,
      bookRoom: `จองห้องพัก`
    },
    cn: {
      subTitle: "歡迎光臨三L酒店",
      title: "芭東海灘度假勝地",
      content: `Triple L Hotel 是一家高級的國際度假酒店，
      位於普吉島最著名的芭東海灘附近的小山丘上，可造訪所有獨特自然及文化吸引力，享受經典的熱帶海灘活動。`,
      aboutHotel: `關於酒店`,
      bookRoom: `Reservation`
    },
  }

  return (
    <Welcome>
      <Container>
        <Row>
          <Col
            md="6"
            lg="5"
            className="d-flex flex-column justify-content-between"
          >
            <SubTitle>{t[lang].subTitle}</SubTitle>
            <Title>{t[lang].title}</Title>
            <p className="pl-md-4">{t[lang].content}</p>

            <p>
              <Link
                to="/about-hotel/"
                className="btn btn-outline-warning btn-md-lg px-4 py-2 mr-3"
              >
                {t[lang].aboutHotel}
              </Link>
              <Link to="/" className="btn btn-primary btn-md-lg py-2 px-4">
                {t[lang].bookRoom}
              </Link>
            </p>
          </Col>

          <Col md="6" lg="7" className="mt-3 mt-md-0">
            <Row>
              <Col md="12" lg="7">
                <Image title="Rooftop Swimming Pool">
                  <img
                    src={overview01}
                    srcSet={`${overview01} 1x, ${overview012x} 2x`}
                    alt="Rooftop Swimming Pool"
                  />
                </Image>
              </Col>
              <Col
                md="12"
                lg="5"
                className="mt-4 mt-lg-0 d-flex flex-wrap justify-content-between align-content-start"
              >
                <ImgSm title="Welcome to Triple L Hotel Phuket">
                  <img
                    src={overviewS02}
                    srcSet={`${overviewS02} 1x, ${overviewS022x} 2x`}
                    alt="Welcome to Triple L Hotel Phuket"
                  />
                </ImgSm>
                <ImgSm title="Thai Decoration at Lobby">
                  <img
                    src={overviewS01}
                    srcSet={`${overviewS01} 1x, ${overviewS012x} 2x`}
                    alt="Thai Decoration at Lobby"
                  />
                </ImgSm>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs="12" lg="6" className="mb-3">
            <Image title="Dining Room">
              <img
                src={overview02}
                srcSet={`${overview02} 1x, ${overview022x} 2x`}
                alt="Dining Room"
              />
            </Image>
          </Col>
          <Col xs="12" lg="6" className="mb-3">
            <Image title="Lobby Decoration">
              <img
                src={overview03}
                srcSet={`${overview03} 1x, ${overview032x} 2x`}
                alt="Lobby Decoration"
              />
            </Image>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md="12" lg="6" className="mb-4 mb-lg-0">
            <Image title="Rooftop Pool">
              <img
                src={overview04}
                srcSet={`${overview04} 1x, ${overview042x} 2x`}
                alt="Rooftop Pool"
              />
            </Image>
          </Col>
          <Col xs="6" lg="3" className="mb-4 mb-lg-0">
            <Image title="Enjoy at Triple L Hotel Phuket">
              <img
                src={overview05}
                srcSet={`${overview05} 1x, ${overview052x} 2x`}
                alt="Enjoy at Triple L Hotel Phuket"
              />
            </Image>
          </Col>
          <Col xs="6" lg="3" className="mb-4 mb-lg-0">
            <Image title="Room Decoration">
              <img
                src={overview06}
                srcSet={`${overview06} 1x, ${overview062x} 2x`}
                alt="Room Decoration"
              />
            </Image>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs="12" lg="6" className="mb-3">
            <Image title="Fitness Center">
              <img
                src={overview07}
                srcSet={`${overview07} 1x, ${overview072x} 2x`}
                alt="Fitness Center"
              />
            </Image>
          </Col>
          <Col xs="12" lg="6" className="mb-3">
            <Image title="Massage Service">
              <img
                src={overview08}
                srcSet={`${overview08} 1x, ${overview082x} 2x`}
                alt="Massage Service"
              />
            </Image>
          </Col>
        </Row>
      </Container>
    </Welcome>
  )
}

export default WelcomeSection
