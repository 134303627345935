import React from "react"
import styled from "@emotion/styled"

import { Carousel } from "react-bootstrap"

import theme from "../theme"

// hero images
import HeroPool from "../../images/hero/hero-pool.jpg"
import HeroLobby from "../../images/hero/hero-lobby.jpg"
import HeroRestaurant from "../../images/hero/hero-restaurant.jpg"
import HeroView from "../../images/hero/hero-view.jpg"

const Hero = styled.div`
  .carousel-inner {
    height: 280px;
  }
  .carousel-item {
    width: 100%;
    overflow: hidden;
  }
  .carousel-item > img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    /* margin-left: -20%; */
  }

  .carousel-item:nth-child(2) > img {
    width: auto;
    height: 320px;
    object-fit: fill;
    margin-left: 0;
  }

  ${theme.breakpoint.sm} {
    .carousel-item > img {
      width: 100%;
      height: auto;
      margin-left: 0;
    }
  }

  ${theme.breakpoint.md} {
    .carousel-inner {
      height: 400px;
    }
    .carousel-item > img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
    .carousel-item:nth-child(2) > img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      margin-left: 0;
    }
  }

  ${theme.breakpoint.xl} {
    .carousel-inner {
      height: 500px;
    }

    .carousel-item > img, .carousel-item:nth-child(2) > img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      margin-left: 0;
    }
  }
`
const hero = () => {
  return (
    <Hero>
      <Carousel indicators={false}>
        <Carousel.Item>
          <img src={HeroPool} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={HeroLobby} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={HeroRestaurant} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={HeroView} alt="First slide" />
        </Carousel.Item>
      </Carousel>
    </Hero>
  )
}

export default hero
