import React from "react"

import SEO from "../components/seo"
import Hero from "../components/home/hero"
import BannerInfo from "../components/home/banner-info"
import Welcome from "../components/home/welcome"
// import Rooms from "../components/home/rooms"
import Services from "../components/home/services"
import Stay from "../components/home/stay"

const IndexPage = props => {
  return (
    <>
      <SEO title="Patong Beach Gateway" />
      <Hero />
      <BannerInfo />
      <Welcome />
      {/* <Rooms /> */}
      <Services />
      <Stay />
    </>
  )
}

export default IndexPage
