import React from "react"
import styled from "@emotion/styled"

import theme from "../theme"
import iconTaxi from "../../images/icons/icon-taxi.png"
import iconFitness from "../../images/icons/icon-fitness.png"
import iconWifi from "../../images/icons/icon-wifi.png"
import iconLaundry from "../../images/icons/icon-laundry.png"
import iconInformation from "../../images/icons/icon-information.png"
import iconRoomService from "../../images/icons/icon-room-service.png"

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const BaseIcon = props => {
  return (
    <span className={props.className}>
      <img src={props.src} alt={props.alt} />
      <span className="title">{props.title}</span>
    </span>
  )
}
const Icon = styled(BaseIcon)`
  height: 90px;
  padding-bottom: 8px;
  background: ${theme.colors.primary};
  display: flex;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  flex-basis: 45%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;

  ${theme.breakpoint.md} {
    flex-basis: 32%;
  }

  ${theme.breakpoint.xl} {
    flex-basis: auto;
    width:135px
  }

  img {
    width: auto;
    max-width: 40px;
    height: 40px;
  }

  .title {
    margin-top: 0.5rem;
    font-size: 0.625rem;
    color: #fff;
    text-transform: uppercase;
  }
`
export default function ServiceIcons() {
  return (
    <Icons>
      <Icon src={iconTaxi} alt="service" title="airport transfer" />
      <Icon src={iconFitness} alt="Fitness" title="Fitness" />
      <Icon src={iconWifi} alt="Free WIFI" title="Free WIFI" />
      <Icon src={iconLaundry} alt="Laundry" title="Laundry" />
      <Icon
        src={iconInformation}
        alt="Tour Information"
        title="Tour Information"
      />
      <Icon src={iconRoomService} alt="Room Service" title="Room Service" />
    </Icons>
  )
}
