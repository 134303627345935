import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"

import { LocaleContext } from "../layout"
import theme from "../theme"
import ServiceIcons from "./service-icons"
import FB04Image from "../../images/content/food.jpg"
import sunsetImage from "../../images/content/sunset.jpg"
import serviceIcon from "../../images/content/icon-service.png"
import restaurantIcon from "../../images/content/icon-restaurant.png"

const RowBs = ({ className, children }) => (
  <Row className={className}>{children}</Row>
)

const ColBs = ({ className, children }) => (
  <Col md="6" className={className}>
    {children}
  </Col>
)

const StyledColImage = styled(ColBs)`
  overflow: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  min-height: 500px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
const StyledColContent = styled(ColBs)`
  padding: 2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 5px solid ${theme.colors.primary};

  ${theme.breakpoint.mdOnly} {
    padding: 1.5rem 2rem 2.5rem !important;
  }
  ${theme.breakpoint.lg} {
    padding: 2rem 2rem 3rem 3rem !important;
  }
  h2 {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  }
  strong {
    color: ${theme.colors.orange};
  }
`
const StyledRowBs = styled(RowBs)`
  margin: 0 !important;

  ${theme.breakpoint.md} {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    &:not(:first-child) {
      ${StyledColContent} {
        border-top: none;
      }
    }
    &.border-b-primary {
      border-bottom: 5px solid ${theme.colors.primary};
    }
  }
`
const Icon = styled.span`
  width: 48px;
  height: 48px;
  display: inline-block;
  margin-bottom: 0.75rem;

  img {
    max-width: 100%;
    height: auto;
  }
`
const HR = styled.hr`
  ${theme.breakpoint.md} {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
`
export default function Services() {
  const {
    pageContext: { lang, defaultPath },
    ...context
  } = React.useContext(LocaleContext)

  const pathPrefix = context.path === defaultPath ? "" : `/${lang}`

  const t = {
    en: {
      header: "Triple L Hotel Services",
      restaurantSubtitle: `Restaurant & Pool bar`,
      restaurantHeader: "Rainbow Bubble Restaurant",
      restaurantHours: "Open Hours",
      restaurantContent: `The 'Rainbow Bubble' restaurant provides a fine choice of
      International Classics and original Thai Cuisine. All dishes are crafted with care
      following local recipes.`,
      readMore: `Read More`,
      serviceHeader: `Services & Concierge`,
      serviceHeader2: `Our services`,
      serviceSubTitle: `Our team will be pleased to assist you`,
      services: `Triple L Hotel is a place where you can unwind and relax with a different 
      tropical rhythm of Phuket. We take guest satisfaction as a top priority. During your stay, 
      staff at Triple L Hotel are always smiling and available to assist with your needs.`,
      otherServices: `Other Services`,
      charge: `Additional charges may apply to some services`,
    },
    th: {
      header: "บริการจาก Triple L Hotel",
      restaurantSubtitle: `Restaurant & Pool bar`,
      restaurantHeader: "Rainbow Bubble Restaurant",
      restaurantHours: "เวลาเปิดให้บริการ",
      restaurantContent: `ร้านอาหาร Rainbow Bubble ให้บริการอารหารไทย และนานาชาติ โดยคัดสรรวัตถุดิบอย่างดี
      อาหารทุกชนิดปรุงด้วยความใส่ใจด้วยส่วนผสมชั้นเลิศในท้องถิ่น`,
      readMore: `อ่านเพิ่มเติม`,
      serviceHeader: `Services & Concierge`,
      serviceHeader2: `Our services`,
      serviceSubTitle: `ทีมงานพร้อมให้บริการด้วยรอยยิ้ม`,
      services: `เมื่อเข้าพักที่ Triple L Hotel แขกของเราสามารถสามารถพักผ่อนได้อย่างเต็มที่ และดื่มด่ำไปกับ 
      บรรยากาศและวัฒนธรรมที่หลากหลายของภูเก็ต เราให้ความสำคัญกับความความพอใจของแขกทุกท่านเป็นลำดับแรก 
      ช่วงเวลาที่พักกับเรา พนักงานต้อนรับและทีมงาน พร้อมให้บริการท่านด้วยความอบอุ่น สะดวกสบายแบบเป็นกันเอง`,
      otherServices: `บริการอื่นๆ`,
      charge: `อาจมีการคิดค่าใช้จ่ายเพิ่มเติมในบางบริการ`,
    },
    cn: {
      header: "3L酒店服務",
      restaurantSubtitle: `餐廳&池畔酒吧`,
      restaurantHeader: "彩虹泡泡餐廳",
      restaurantHours: "開放時間",
      restaurantContent: `“彩虹泡泡”餐廳提供精美的各國美食和傳統泰國美食。所有美食均遵循當地古法精心烹製。`,
      readMore: `Read More`,
      serviceHeader: `服務&接待`,
      serviceHeader2: `我們的服務`,
      serviceSubTitle: `我們的團隊很樂意為您提供幫助`,
      services: `Triple L Hotel酒店是您放鬆身心並享受普吉島另一種熱帶風情的地方。我們以客為尊的宗旨。在您住宿期間，
      Triple L Hotel的工作人員總是愉快的面帶微笑，隨時為您提供服務。`,
      otherServices: `其他服務`,
      charge: `部分服務需要支付額外費用`,
    },
  }

  return (
    <section className="py-5">
      <h2 className="text-center font-weight-bold mb-5">{t[lang].header}</h2>
      <Container>
        <div className="shadow">
          <StyledRowBs>
            <StyledColImage>
              <img
                src={FB04Image}
                alt="Poolbar at Triple L Hotel"
                className="content-image"
              />
            </StyledColImage>

            <StyledColContent>
              <div className="align-self-end d-none d-md-block">
                <small>Triple L Phuket</small>
              </div>
              <HR />
              <Icon>
                <img src={restaurantIcon} alt="" />
              </Icon>
              <small>{t[lang].restaurantSubtitle}</small>
              <h2>{t[lang].restaurantHeader}</h2>
              <span>
                <strong>{t[lang].restaurantHours}:</strong> 6.00 - 24.00 hrs.
              </span>
              <HR />
              <p>{t[lang].restaurantContent}</p>
              <p className="m-0">
                <Link
                  to={`${pathPrefix}/restaurant-bar`}
                  className="btn btn-primary shadow align-self-end"
                >
                  {t[lang].readMore}
                </Link>
              </p>
            </StyledColContent>
          </StyledRowBs>

          <StyledRowBs className="border-b-primary">
            <StyledColImage>
              <img
                src={sunsetImage}
                alt="Poolbar at Triple L Hotel"
                className="content-image"
              />
            </StyledColImage>
            <StyledColContent>
              <div className="align-self-end d-none d-md-block">
                <small>Triple L Phuket</small>
              </div>
              <HR />
              <Icon>
                <img src={serviceIcon} alt="" />
              </Icon>
              <small>{t[lang].serviceHeader2}</small>
              <h2>{t[lang].serviceHeader}</h2>
              <span>
                <strong>{t[lang].serviceSubTitle}</strong>
              </span>
              <HR />
              <p>{t[lang].services}</p>
              <p>
                <Link
                  to={`${pathPrefix}/restaurant-bar`}
                  className="btn btn-primary shadow"
                >
                  {t[lang].readMore}
                </Link>
              </p>
            </StyledColContent>
          </StyledRowBs>

          <Row>
            <Col md="11" className="m-auto">
              <div className="bg-light my-md-5 p-4 p-md-5 shadow-sm">
                <h4 className="mb-3">{t[lang].otherServices}</h4>
                <ServiceIcons />
                <p className="mt-3 mb-0">
                  <small>
                    <em>* {t[lang].charge}</em>
                  </small>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}
