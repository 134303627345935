import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Container, Row, Col } from "react-bootstrap"

import { LocaleContext } from "../layout"
import theme from "../theme"
import blueBg from "../../images/content/girl-bg.jpg"
import girl from "../../images/content/girl.png"

const Section = styled.section`
  margin: 1rem 0 5rem;
  position: relative;

  ${theme.breakpoint.md} {
    margin: 5rem 0;
  }
`

const ColBs = ({ className, children }) => (
  <Col className={className}>{children}</Col>
)

const Bg = styled(ColBs)`
  background: url("${blueBg}") no-repeat;
  background-position: top left;
  min-height: 350px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  display:flex;
  align-items:center;

  ${theme.breakpoint.md}{
    background-position: top right;
  }
`
const Model = styled.div`
  background: url("${girl}");
  width:655px;
  height:437px;
  position:absolute;
  display:none;
  bottom: -2px;
  right: 55%;
  z-index:99;

  ${theme.breakpoint.md}{
    display:block;
  }
  ${theme.breakpoint.lg}{
    right: 62%;
  }
`
const Content = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  text-align: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

  h2 {
    margin: 0;
    font-size: 2.75rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    line-height: 0.9;
    margin-bottom: 1rem;
  }
  strong {
    font-size: 1.25rem;
    color: ${theme.colors.orange};
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  ${theme.breakpoint.md} {
    width: 45%;
  }
`
export default function Stay() {
  const {
    pageContext: { lang, defaultPath },
    ...context
  } = React.useContext(LocaleContext)

  const pathPrefix = context.path === defaultPath ? "" : `/${lang}`

  const t = {
    en:{
      header:`Enjoy your stay`,
      content: `Join us at Triple L Hotel to enjoy modern, stylish accommodation
      offering sophisticated relaxation.`,
      contact:`Contact us`
    },
    th:{
      header:`Enjoy your stay`,
      content: `สำรองที่พักกับ Triple L Hotel วันนี้ พบบรรยากาศผ่อนคลาย
      เป็นกันเอง ดื่มด่ำกับสายลมทะเลและธรรมชาติของภูเก็ต พร้อมสิ่งอำนวยความสะดวกที่ทันสมัย`,
      contact:`ติดต่อเรา`
    },
    cn:{
      header:`入住愉快`,
      content: `加入我們，在Triple L Hotel享受現代時尚的住宿，為您提供精緻的放鬆。`,
      contact:`聯繫我們`
    }
  }
  return (
    <Section>
      <Container>
        <Row>
          <Bg>
            <Model />
            <Content>
              <h2>
                {t[lang].header}
                <br />
                <strong>Triple L Hotel Phuket</strong>
              </h2>
              <p className="my-4">
                {t[lang].content}
              </p>
              <Link to={`${pathPrefix}/contac`} className="btn btn-info text-white py-2 px-3">
              {t[lang].contact}
              </Link>
            </Content>
          </Bg>
        </Row>
      </Container>
    </Section>
  )
}
